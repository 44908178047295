import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UserGuard } from './guards/user.guard';

const routes: Routes = [
  {
    canActivate: [UserGuard],
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    // canActivate: [UserGuard],
    path: '',
    redirectTo: 'home-menu',
    pathMatch: 'full'
  },
  {
    canActivate: [UserGuard],
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    canActivate: [UserGuard],
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    canActivate: [UserGuard],
    path: 'menu-qcm',
    loadChildren: () => import('./menu-qcm/menu-qcm.module').then( m => m.MenuQcmPageModule)
  },
  {
    canActivate: [UserGuard],
    path: 'ajoutqcm',
    loadChildren: () => import('./ajoutqcm/ajoutqcm.module').then( m => m.AjoutqcmPageModule)
  },
  {
    canActivate: [UserGuard],
    path: 'home-menu',
    loadChildren: () => import('./home-menu/home-menu.module').then( m => m.HomeMenuPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'my-qcm',
    loadChildren: () => import('./my-qcm/my-qcm.module').then( m => m.MyQcmPageModule)
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'categorie-doc',
    loadChildren: () => import('./categorie-doc/categorie-doc.module').then( m => m.CategorieDocPageModule)
  },
  {
    path: 'document',
    loadChildren: () => import('./document/document.module').then( m => m.DocumentPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginFormInterface } from '../interfaces/loginForm.interface';
import { ToastController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  // baseUrl: string = " http://192.168.99.224:3000/"
  baseUrl: string = environment.urlRacine;

  urlLogin: string = `${this.baseUrl}auth/login`
  urlGetDomaine: string = `${this.baseUrl}qcm/dom`;
  urlGetQuestion: string = `${this.baseUrl}qcm/question`
  urlNewQcm: string = `${this.baseUrl}qcm/ajout`;
  urlGetMyQcm: string = `${this.baseUrl}qcm/getMyQcm`;
  urlDeleteMyQcm: string = `${this.baseUrl}qcm/updateMyQcm`;
  urlSaveHistory: string = `${this.baseUrl}qcm/saveHistorique`;
  urlgetUserHistory: string = `${this.baseUrl}qcm/getHistorique`;
  urlGetListCategorie: string = `${this.baseUrl}doc/listeCategorie`;
  urlfindDocumentByCategorie: string = `${this.baseUrl}doc/findDocumentByCategorie`;
  urlCreateDocument: string = `${this.baseUrl}doc/create`;
  urlUserDocument = `${this.baseUrl}doc/getDocumentAddedByUser`;
  urlDeleteDocument = `${this.baseUrl}doc/deleteDocument`;





  // variable de connexion
  isLogedIn: boolean = false;

  // variables partager
  domaine: string;
  documents: any;

  //header

  headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');



  constructor(public router: Router, public http: HttpClient, private toastController: ToastController) {
    this.autoConnexion();
  }

  async toastGenerator($message) {
    const toast = await this.toastController.create({
      message: $message,
      duration: 4000,
      position: 'top'
    });

    await toast.present();
  }


  autoConnexion() {
    let token = localStorage.getItem('token');

    if (token) {
      this.isLogedIn = true;
      this.headers = new HttpHeaders({
        'Authorization': `bearer ${token}`
      });
      this.router.navigate(['/home-menu']);
    }
    else {
      this.isLogedIn = false;
    }
  }


  login($userLogin: LoginFormInterface) {
    return this.http.post(this.urlLogin, $userLogin);
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['login']);
  }



  getDomaine() {
    return this.http.get(this.urlGetDomaine, { headers: this.headers });
  }

  getQuestion($domaine: string) {
    const domaine = {
      domaine: $domaine
    };
    return this.http.post(this.urlGetQuestion, domaine, { 'headers': this.headers });
  }

  addNewQcm($newqcm) {
    return this.http.post(this.urlNewQcm, $newqcm, { headers: this.headers });
  }

  // recuperation liste qcm ajouter par utilisateur
  getListMyQcm() {
    return this.http.get(this.urlGetMyQcm, { headers: this.headers });
  }

  // suppression liste qcm ajouter par utilisateur
  deleteMyQcm($qcmToDelete: any) {
    return this.http.post(this.urlDeleteMyQcm, $qcmToDelete, { headers: this.headers });
  }

  saveHistory(historyToSave: any) {
    return this.http.post(this.urlSaveHistory, historyToSave, { headers: this.headers });
  }

  getUserHistory() {
    return this.http.get(this.urlgetUserHistory, { headers: this.headers });
  }

  getListCategorieDoc() {
    return this.http.get(this.urlGetListCategorie, { headers: this.headers });
  }

  findDocumentByCategorie($categorie: any) {
    return this.http.post(this.urlfindDocumentByCategorie, $categorie, { headers: this.headers });
  }

  createDocument($document: any) {
    return this.http.post(this.urlCreateDocument, $document, { headers: this.headers });
  }

  userDocument() {
    return this.http.get(this.urlUserDocument, { headers: this.headers });
  }

  deleteDocument($document) {
    return this.http.post(this.urlDeleteDocument, $document, { headers: this.headers });
  }



}
